<template>
    <div class="home">
        <x-header back>角色维护</x-header>
        <x-content v-if="loading" style="height:100%;" :contentStyle="{alignItems:'center',justifyContent:'center',height:'100%'}">
            <bounce-loading></bounce-loading>
        </x-content>
        <x-content v-else  :contentStyle="{display:'block',width:'calc(100% - 32px)',padding:'16px'}">
            <div class="search_warp">
                <el-row class="list-control_warp">
                    <el-col :span="20">
                        &nbsp;
                        <el-button size="medium" type="primary" @click="$refs['addRoleDialog'].show()" icon="base-icon-plus">
                            <span style="display: inline-flex; align-items: center;">
                                <span>新增角色</span>
                            </span>
                        </el-button>
                    </el-col>
                </el-row>
            </div>
            <x-card>
                <el-table
                    :data="datas"
                    stripe
                    style="width: 100%">
                    <el-table-column
                        prop="name"
                        label="角色">
                    </el-table-column>
                    <el-table-column
                        prop="code"
                        label="CODE">
                    </el-table-column>
                    <el-table-column
                        width="100"
                        label="操作">
                        <template slot-scope="scope">
                           <el-button type="primary" size="mini" @click="editPermission(scope.row)">分配权限</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </x-card>
        </x-content>
        <app-dialog  ref="permissionDialog" dialogTitle="权限分配" originTitle staticDialog width="400px">
            <el-tree
                ref="tree"
                :data="permissionDatas"
                :props="defaultProps"
                node-key="id"
                check-strictly
                default-expand-all
                show-checkbox>
                </el-tree>
            <div class="save_btn_warp">
                <el-button type="primary" style="width:50%;" size="medium" :loading="savePermissionLoading" @click="savePermission">保存</el-button>
            </div>
        </app-dialog>
        <app-dialog  ref="addRoleDialog" dialogTitle="新增角色" originTitle staticDialog width="400px">
            <el-form ref="form" label-position="top" :rules="rules" :model="form">
				<el-form-item prop="name">
					<el-input placeholder="角色名(必填)" v-model="form.name">
					</el-input>
				</el-form-item>
				<el-form-item prop="code">
					<el-input placeholder="CODE(必填)" v-model="form.code">
					</el-input>
				</el-form-item>
            </el-form>
            <div class="save_btn_warp">
                <el-button type="primary" style="width:50%;" size="medium" :loading="saveRoleLoading" @click="saveRole">保存</el-button>
            </div>
        </app-dialog>
    </div>
</template>
<script>
    import { GetRoles,GetPermissions,SaveRole,UpdateRolePermission } from "../../service/user"
    import { parseTreeData } from "../../util/util";
    let codeData = [];
    export default {
        name:'RoleManager',
        data(){
            var checkCode = (rule, value, callback) => {
                if (!value) {
                    callback(new Error('CODE码不能为空'));
                    return;
                }
                if(codeData.includes(value)){
                    callback(new Error('当前CODE码已存在，请重新填写'));
                    return;
                }
                callback();
            };
            return {
                loading:false,
                saveRoleLoading:false,
                savePermissionLoading:false,
                datas:[],
                permissionDatas:[],
                permissionMetaDatas:[],
                defaultProps: {
                    children: 'children',
                    label: 'label'
                },
                form:{
                    name:'',
                    code:''
                },
                rules:{
                    name:[{required:true,message:'请输入角色名称',trigger:'blur'}],
                    code:[{ validator: checkCode, trigger: 'blur' }]
                },
                checkRoleId:''
            }
        },
        created(){
            this.getRoles();
            this.getPermissions();
        },
        methods:{
            getRoles(){
                this.loading = true;
                GetRoles().then(e => {
                    let data = this.APIParse(e);
                    this.datas = data;
                    codeData = data.map(e => e.code)
                }).catch(e => {
                    this.$message.error(e.message)
                }).finally(e => {
                    this.loading = false;
                })
            },
            getPermissions(){
                GetPermissions().then(e => {
                    let data = this.APIParse(e);
                    let treeData = parseTreeData(data,0)
                    this.permissionDatas = treeData;
                    this.permissionMetaDatas = data;
                }).catch(e => {
                    this.$message.error(e.message)
                })
            },
            editPermission(data){
                this.checkRoleId = data.id
                this.$refs['permissionDialog'].show()
                setTimeout(() => {
                    this.$refs.tree.setCheckedKeys([])
                    this.$refs.tree.setCheckedKeys((data.permissionList || []).map(e => e.id))
                },100)
            },
            saveRole(){
                this.$refs['form'].validate(valid => {
					if (valid) {
                        this.saveRoleLoading = true;
                        SaveRole(this.form.name,this.form.code).then(e => {
                            let data = this.APIParse(e);
                            if(data){
                                this.$message.success("角色添加成功")
                                this.getRoles();
                                this.$refs['addRoleDialog'].hide();
                                this.form.name = ''
                                this.form.code = ''
                            }
                        }).catch(e => {
                            this.$message.error(e.message)
                        }).finally(e => {
                            this.saveRoleLoading = false;
                        })
					} else {
                        setTimeout(() => {
                        document.getElementsByClassName("is-error")[0].scrollIntoView({
                            behavior: "smooth",
                             block: "center"
                         });
                    }, 0);
						return false;
					}
				})
			
            },
            savePermission(){
                if(this.checkRoleId){
                    this.savePermissionLoading = true;
                    UpdateRolePermission(this.checkRoleId,this.$refs.tree.getCheckedKeys().join(',')).then(e => {
                        let data = this.APIParse(e);
                        if(data){
                            this.$message.success("权限分配成功")
                            this.getRoles();
                            this.$refs['permissionDialog'].hide();
                            this.checkRoleId = ''
                        }
                    }).catch(e => {
                        this.$message.error(e.message)
                    }).finally(e => {
                        this.savePermissionLoading = false;
                    })
                }else{
                    this.$message.error('系统异常，请刷新重试')
                }
            }
        }
    }
</script>
<style lang="scss" scoped>
    .home{width: 100%;height: 100%;}
    .search_warp{width:100%;margin: 16px 0px;}
    
    .save_btn_warp{width: 100%;margin-top: 32px;display: flex;align-items: center;justify-content: center;}
</style>